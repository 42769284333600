<template>
  <section style="width:100%;">
    <div class="container">
        
        <div class="section-header">
          <img src="/assets/Invrs Logo Green_White.svg" width="180" style="vertical-align:middle;margin-top:5px;margin-left:auto;margin-right:auto;" class="fin-triv-logo"/>
          <span class="heading-secondary">&nbsp;FinTrivia Monthly Cash Contest!</span>
          <div class="now-playing">
         
          <p class="heading-description" >
             
            <small>Every month $25 to $1000 in prizes!<br>
           Free entry, full contest details <router-link class="footer-link" :to="{ name: 'fintrivia-rules' }">here.</router-link>
            </small>   
          </p>
          </div>
        </div>
        
      </div>
    
  </section>
</template>

<script>
export default {
  name: 'PromoSpot',
  data() {
    return {
     
      obsOptionsData: {
        root: null,
        threshold: 0.2,
      },
      section: "",
    };
  },

  mounted: function () {
   
   
    // this.section = document.querySelector(".section-data");
    // const dataSectionObserver = new IntersectionObserver(
    //   this.revealSection,
    //   this.obsOptionsData
    // );
    // dataSectionObserver.observe(this.section);
  },

  methods: {
    

    

    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
  },
};
</script>

<style scoped>

.now-playing{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}

.q-trade{
  margin-top:-3rem;
}

 .fin-triv-logo{
    margin-top:-.8rem;
  }

section {
  padding-left: 9.6rem;
  padding-right: 9.6rem;
  background-color: #32364e;
  /* transform: skewY(3deg); */
  box-shadow: inset 4.8rem 4.8rem 9.6rem #2b2e42,
    inset -4.8rem -4.8rem 9.6rem #3a3e5a;
  border-radius:16px;
}

.container {
  /* transform: skewY(-3deg); */
  width: 100%;
}

.section-header {
  display: flex;
  flex-direction: column;
  text-align:center;
}

.subheading {
  color: #33cc99;
}

.heading-secondary {
  color: #fff;
  font-size: 3.8rem;
  padding-top:1rem;
  margin-bottom: 0;
}

.heading-description {
  line-height: 3.5rem;
  /* width: 60%; */
  color: #f2f4fa;
  margin-bottom: 1.8rem;
}



.section-data {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: skewY(-3deg);
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
 
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  
  .now-playing{
    display:flex;
    flex-direction:column;
    /* justify-content: space-around; */
  }
   .q-trade{
    margin-top:0;
  }

  .fin-triv-logo{
    margin-top:0;
  }

.heading-secondary {
  
  font-size: 2.8rem;
  
}

  .heading-description {
    width: 100%;
  }

  .slide-icon {
    width: 7%;
    margin-right: 1.6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  
}
</style>
